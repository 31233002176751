import gql from 'graphql-tag';

export const GET_PRODUCT_VARIANT_LIST_SIMPLE = gql`
    query GetProductVariantListSimple($options: ProductVariantListOptions!, $productId: ID) {
        productVariants(options: $options, productId: $productId) {
            items {
                id
                name
                sku
                featuredAsset {
                    id
                    preview
                    focalPoint {
                        x
                        y
                    }
                }
                product {
                    id
                    featuredAsset {
                        id
                        preview
                        focalPoint {
                            x
                            y
                        }
                    }
                }
            }
            totalItems
        }
    }
`;

export const GET_PRODUCT_VARIANT = gql`
    query GetProductVariant($id: ID!) {
        productVariant(id: $id) {
            id
            name
            sku
            stockOnHand
            stockAllocated
            stockLevel
            useGlobalOutOfStockThreshold
            featuredAsset {
                id
                preview
                focalPoint {
                    x
                    y
                }
            }
            price
            priceWithTax
            product {
                id
                featuredAsset {
                    id
                    preview
                    focalPoint {
                        x
                        y
                    }
                }
            }
        }
    }
`;
