import gql from 'graphql-tag';
import { PRODUCT_CELL_FRAGMENT } from '../../common/fragments.graphql';

export const GET_PRODUCT_CELLS = gql`
    query GetProductCells($options: ProductCellListOptions) {
        productCells(options: $options) {
            items {
                ...ProductCell
            }
            totalItems
        }
    }
    ${PRODUCT_CELL_FRAGMENT}
`;
