import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    BulkUpdateJofemarNuukChannelProductVariantDocument,
    BulkUpdateJofemarNuukChannelStockDocument,
    GetJofemarNuukCollectionDocument,
    GetJofemarNuukTraysDocument,
    GetJofemarNuukTraysQuery,
    SyncJofemarNuukDocument,
} from '../../generated-types';
import {
    DataService,
    ModalService,
    NotificationService,
    PermissionsService,
    SharedModule,
} from '@vendure/admin-ui/core';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    EMPTY,
    finalize,
    interval,
    merge,
    Observable,
    of,
    Subject,
    timer,
} from 'rxjs';
import { debounceTime, groupBy, map, mergeMap, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { ProductVariantSelectDialogComponent } from '../product-variant-select-dialog/product-variant-select-dialog.component';
import { DeliverType } from '../../ui-types';

type Modes = 'home' | 'edit' | 'increase' | 'decrease';

@Component({
    selector: 'agrofreeze-channels',
    templateUrl: './jofemar-nuuk-channel-list.component.html',
    styleUrls: ['./jofemar-nuuk-channel-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class JofemarNuukChannelListComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    jofemarNuuk$: Observable<{ id: string; isSyncing: boolean }>;
    private refreshJofemar$ = new BehaviorSubject<void>(undefined);
    private itemsSubject = new BehaviorSubject<GetJofemarNuukTraysQuery['jofemarNuukTrays']['items']>([]);
    items$ = this.itemsSubject.asObservable();
    private updateStockSubject = new Subject<{ id: string; inStock: number }>();
    panels: { [key: string]: boolean } = {};
    private stockUpdates: { [key: string]: number } = {};
    private loadingStates: { [key: string]: boolean } = {};
    private previousStocks: { [key: string]: number } = {};
    @Input() mode: Modes = 'home';
    modeOptions = [
        {
            id: 'home',
            value: 'home',
            title: 'Bekijk voorraad',
            iconShape: 'home',
            iconDirection: '',
        },
        {
            id: 'increase',
            value: 'increase',
            title: 'Verhoog voorraad',
            iconShape: 'arrow',
            iconDirection: '',
        },
        {
            id: 'decrease',
            value: 'decrease',
            title: 'Verlaag voorraad',
            iconShape: 'arrow',
            iconDirection: 'down',
        },
        {
            id: 'edit',
            value: 'edit',
            title: 'Wijzig product',
            iconShape: 'pencil',
            iconDirection: '',
        },
    ];

    constructor(
        private readonly modalService: ModalService,
        private readonly dataService: DataService,
        private readonly notificationService: NotificationService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly permissionsService: PermissionsService,
    ) {}

    ngOnInit(): void {
        this.dataService
            .query(GetJofemarNuukTraysDocument)
            .refetchOnChannelChange()
            .mapSingle(data =>
                data.jofemarNuukTrays.items.filter(
                    ({ jofemarNuukChannels }) => jofemarNuukChannels.length > 0,
                ),
            )
            .subscribe(items => this.itemsSubject.next(items));

        this.refreshJofemar$
            .pipe(
                switchMap(() =>
                    merge(
                        timer(0), // Emit immediately
                        interval(5000), // Continue emitting every 5 seconds
                    ).pipe(
                        switchMap(() =>
                            this.permissionsService.userHasPermissions(['ManageJofemarNuukStock'])
                                ? this.dataService
                                      .query(GetJofemarNuukCollectionDocument)
                                      .refetchOnChannelChange()
                                      .mapSingle(data => {
                                          this.jofemarNuuk$ = of(data.jofemarNuukCollection.items[0]);
                                          this.changeDetectorRef.markForCheck();
                                          return data.jofemarNuukCollection.items[0];
                                      })
                                : of(null),
                        ),
                        takeWhile(jofemarNuuk => jofemarNuuk !== null && jofemarNuuk.isSyncing, true),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe();

        this.updateStockSubject
            .pipe(
                groupBy(stockUpdate => stockUpdate.id),
                mergeMap(group$ =>
                    group$.pipe(
                        debounceTime(300),
                        switchMap(({ id, inStock }) => {
                            const input = {
                                ids: [id],
                                inStock,
                            };
                            this.loadingStates[id] = true;
                            this.changeDetectorRef.markForCheck();
                            return this.dataService
                                .mutate(BulkUpdateJofemarNuukChannelStockDocument, {
                                    input,
                                })
                                .pipe(
                                    map(() => ({ id, inStock: input.inStock })),
                                    finalize(() => {
                                        delete this.previousStocks[id];
                                        this.loadingStates[id] = false;
                                        this.changeDetectorRef.markForCheck();
                                    }),
                                    catchError(() => {
                                        // If mutation fails, revert stock to previous value
                                        this.stockUpdates[id] = this.previousStocks[id];
                                        const revertedItems = this.itemsSubject.value.map(item => ({
                                            ...item,
                                            jofemarNuukChannels: item.jofemarNuukChannels.map(
                                                jofemarNuukChannel =>
                                                    jofemarNuukChannel.id === id
                                                        ? {
                                                              ...jofemarNuukChannel,
                                                              inStock: this.previousStocks[id],
                                                          }
                                                        : jofemarNuukChannel,
                                            ),
                                        }));
                                        this.itemsSubject.next(revertedItems);
                                        this.notificationService.error(
                                            'Er is een fout opgetreden bij het bijwerken van de voorraad. Probeer het opnieuw.',
                                        );
                                        this.loadingStates[id] = false;
                                        this.changeDetectorRef.markForCheck();
                                        return EMPTY;
                                    }),
                                );
                        }),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    setMode(mode: Modes): void {
        this.mode = mode;
    }

    togglePanel(panel: string): void {
        this.panels[panel] = !this.panels[panel];
    }

    trackByJofemarNuukTrayId(
        index: number,
        item: GetJofemarNuukTraysQuery['jofemarNuukTrays']['items'][0],
    ): string {
        return item.id;
    }

    trackByJofemarNuukChannelId(
        index: number,
        item: GetJofemarNuukTraysQuery['jofemarNuukTrays']['items'][0],
    ): string {
        return item.id;
    }

    syncJofemarNuuk(): void {
        combineLatest([
            this.modalService.dialog({
                title: 'agromaat.dialog.start-sync-agrofreeze.title',
                buttons: [
                    { label: 'Annuleren', type: 'secondary' },
                    { label: 'Synchroniseren', type: 'primary', returnValue: true },
                ],
                body: 'agromaat.dialog.start-sync-agrofreeze.body',
            }),
            this.jofemarNuuk$,
        ])
            .pipe(
                switchMap(([response, { id: jofemarNuukId }]) =>
                    response
                        ? this.dataService.mutate(SyncJofemarNuukDocument, {
                              input: { jofemarNuukId },
                          })
                        : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.syncJofemarNuuk.started) {
                    this.notificationService.success('agromaat.dialog.start-sync-agrofreeze.success');
                } else {
                    this.notificationService.error('agromaat.dialog.start-sync-agrofreeze.failed');
                }
                this.jofemarNuuk$ = this.jofemarNuuk$.pipe(
                    map(jofemarNuuk => ({
                        ...jofemarNuuk,
                        isSyncing: true,
                    })),
                );
                setTimeout(() => this.refreshJofemarNuuk(), 5000);
            });
    }

    refreshJofemarNuuk(): void {
        this.refreshJofemar$.next();
    }

    refresh() {}

    update({
        id,
        inStock,
        productVariant,
    }: {
        id: string;
        inStock: number;
        productVariant: { id: string };
    }): void {
        if (this.mode === 'edit') {
            const ids = [id];
            this.modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        deliverDevice: DeliverType.JOFEMAR_NUUK,
                        excludeIds: productVariant ? [productVariant.id] : [],
                    },
                })
                .subscribe(id => {
                    if (id) {
                        this.dataService
                            .mutate(BulkUpdateJofemarNuukChannelProductVariantDocument, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    if (
                                        result.bulkUpdateJofemarNuukChannelProductVariant[0].__typename !==
                                        'JofemarNuukChannel'
                                    ) {
                                        this.notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFreeze kanaal',
                                        });
                                        return;
                                    }

                                    const updated = result.bulkUpdateJofemarNuukChannelProductVariant[0];
                                    const updatedItems = this.itemsSubject.value.map(item => ({
                                        ...item,
                                        jofemarNuukChannels: item.jofemarNuukChannels.map(
                                            jofemarNuukChannel =>
                                                jofemarNuukChannel.id === updated.id
                                                    ? updated
                                                    : jofemarNuukChannel,
                                        ),
                                    }));
                                    this.itemsSubject.next(updatedItems as any);
                                },
                                () =>
                                    this.notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFreeze kanaal',
                                    }),
                            );
                    }
                });
            return;
        }

        if (this.previousStocks[id] === undefined) {
            this.previousStocks[id] = inStock;
        }

        // Immediately update the local stock state
        this.stockUpdates[id] = Math.max(0, inStock + (this.mode === 'increase' ? 1 : -1));

        // Update the UI immediately
        const updatedItems = this.itemsSubject.value.map(item => ({
            ...item,
            jofemarNuukChannels: item.jofemarNuukChannels.map(jofemarNuukChannel =>
                jofemarNuukChannel.id === id
                    ? { ...jofemarNuukChannel, inStock: this.stockUpdates[id] }
                    : jofemarNuukChannel,
            ),
        }));
        this.itemsSubject.next(updatedItems);

        // Emit the event to trigger to debounce
        this.updateStockSubject.next({ id, inStock: this.stockUpdates[id] });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
