import { DataService, RelationSelectorDialogComponent, SharedModule } from '@vendure/admin-ui/core';
import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { GetProductVariantListSimpleQuery, ProductVariant } from '../../generated-types';
import { debounceTime, switchMap } from 'rxjs/operators';
import { GET_PRODUCT_VARIANT_LIST_SIMPLE } from '../filtered-vdr-relation-product-variant-input/filtered-relation-product-variant-input.graphql';
import { DeliverType } from '../../ui-types';

@Component({
    selector: 'product-variant-select-dialog',
    templateUrl: './product-variant-select-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ProductVariantSelectDialogComponent extends RelationSelectorDialogComponent implements OnInit {
    searchControl = new UntypedFormControl('');
    searchTerm$ = new Subject<string>();
    @Input() deliverDevice: DeliverType;
    @Input() productCellType: string | undefined;
    @Input() cooling: string | undefined;
    @Input() excludeIds: string[] = [];
    results$: Observable<GetProductVariantListSimpleQuery['productVariants']['items']>;
    @ViewChild('selector') template: TemplateRef<any>;
    selectedState: ProductVariant | '' = '';
    cancellable: boolean;

    constructor(private dataService: DataService) {
        super();
    }

    ngOnInit() {
        this.selectorTemplate = this.template;
        this.results$ = this.searchTerm$.pipe(
            debounceTime(200),
            switchMap(term => {
                const filter: Record<string, unknown> = {
                    deliverDevice: {
                        eq: this.deliverDevice,
                    },
                };
                if (this.excludeIds.length) {
                    filter.id = {
                        notIn: this.excludeIds,
                    };
                }
                if (this.productCellType && this.deliverDevice === 'vending') {
                    filter.productCellTypes = {
                        inList: this.productCellType,
                    };
                }
                if (this.cooling && this.deliverDevice === 'vending') {
                    filter.cooling = {
                        eq: this.cooling,
                    };
                }
                if (term) {
                    filter.name = {
                        contains: term,
                    };
                }
                return this.dataService
                    .query<GetProductVariantListSimpleQuery>(GET_PRODUCT_VARIANT_LIST_SIMPLE, {
                        options: {
                            filter,
                            take: 10,
                        },
                    })
                    .mapSingle(data => data.productVariants.items);
            }),
        );
    }

    select(event: ProductVariant | '') {
        if (event !== '') {
            this.resolveWith([event.id]);
        }
    }

    cancel() {
        this.resolveWith();
    }
}
