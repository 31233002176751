import gql from 'graphql-tag';

export const GET_PRODUCT_LIST_POSITION = gql`
    query GetProductListPosition {
        products(options: { take: 1000, sort: { position: ASC }, filter: { enabled: { eq: true } } }) {
            items {
                id
                name
                featuredAsset {
                    preview
                }
                customFields {
                    position
                }
            }
        }
    }
`;

export const MOVE_PRODUCT = gql`
    mutation MoveProduct($input: MoveProductInput!) {
        moveProduct(input: $input) {
            id
        }
    }
`;
