import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DataService, SharedModule } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_PRODUCT_CELLS } from './product-cells-overview.graphql';
import { GetProductCellsQuery } from '../../generated-types';

type Item = GetProductCellsQuery['productCells']['items'][number];

@Component({
    selector: 'product-cells-overview',
    templateUrl: './product-cells-overview.component.html',
    styleUrls: ['./product-cells-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ProductCellsOverviewComponent implements OnInit {
    cells$: Observable<Item[][]>;

    constructor(private dataService: DataService) {}

    ngOnInit(): void {
        this.cells$ = this.dataService
            .query<GetProductCellsQuery>(GET_PRODUCT_CELLS)
            .refetchOnChannelChange()
            .mapStream(data => data.productCells.items)
            .pipe(map(items => this.splitByColumns(items)));
    }

    private splitByColumns(items: Item[]): Item[][] {
        const grouped = items.reduce((acc: Record<number, Item[]>, item) => {
            const colValue = item!.col;
            if (!acc[colValue]) {
                acc[colValue] = [];
            }
            acc[colValue].push(item);
            return acc;
        }, {});

        return Object.values(grouped);
    }
}
