import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog, SharedModule } from '@vendure/admin-ui/core';

@Component({
    selector: 'update-stock-dialog',
    templateUrl: './update-stock-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class UpdateStockDialogComponent implements Dialog<number> {
    resolveWith: (result?: number) => void;
    stock: number;

    submit() {
        this.resolveWith(this.stock);
    }

    cancel() {
        this.resolveWith();
    }
}
