import { registerPageTab } from '@vendure/admin-ui/core';

import { OrderProductListComponent } from './components/order-product-list/order-product-list.component';

export default [
    registerPageTab({
        location: 'product-list',
        tab: 'Sorteer producten',
        route: 'order-product',
        component: OrderProductListComponent,
    }),
];
