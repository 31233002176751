<vdr-page-detail-layout>
  <div class="grid-container">
    <ng-container *ngFor="let columnItems of cells$ | async">
      <div class="grid-column">
        <ng-container *ngFor="let productCell of columnItems">
          <div class="grid-item grid-item-{{ productCell.type }} grid-item-{{ productCell.state | lowercase }}">
            <div class="grid-item-door"></div>
            <div
                class="img-placeholder"
              *ngIf="
                productCell.productVariant as productVariant;
                else noProduct
              "
            >
              <img
                *ngIf="
                  productVariant.featuredAsset ||
                    productVariant.product.featuredAsset as asset;
                  else noImage
                "
                [src]="asset | assetPreview: 'thumb'"
                alt="{{ productCell.id }} - {{
                  productCell.productVariant.name
                }}"
              />
            </div>
            <ng-template #noProduct>
              <div class="fallback-product-div">
                <p>Nog geen product gekoppeld</p>
              </div>
            </ng-template>
            <ng-template #noImage>
              <div class="fallback-image-div">
                <p>
                  {{ productCell.productVariant.name }}
                </p>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</vdr-page-detail-layout>
