<vdr-data-table-2
  id="product-modules-overview"
  [items]="items$ | async"
  [itemsPerPage]="itemsPerPage$ | async"
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async"
  (pageChange)="setPageNumber($event)"
  [filters]="filters"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  <vdr-bulk-action-menu
    locationId="product-modules-list"
    [hostComponent]="this"
    [selectionManager]="selectionManager"
  />
  <vdr-dt2-column [heading]="'common.id' | translate" [sort]="sorts.get('id')">
    <ng-template let-productModule="item">{{ productModule.id }}</ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column
    [heading]="'agromaat.common.type' | translate"
    [sort]="sorts.get('type')"
  >
    <ng-template let-productModule="item">{{ productModule.type }}</ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column
    [heading]="'agromaat.common.serialNumber' | translate"
    [sort]="sorts.get('serialNumber')"
  >
    <ng-template let-productModule="item">{{
      productModule.serialNumber
    }}</ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column
    [heading]="'agromaat.common.connectivityStatus' | translate"
    [sort]="sorts.get('connectivityStatus')"
  >
    <ng-template let-productModule="item">
      {{ productModule.connectivityStatus }}
    </ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column
    [heading]="'agromaat.common.lastConnectivityUpdate' | translate"
    [sort]="sorts.get('lastConnectivityUpdate')"
  >
    <ng-template let-productModule="item">
      {{ productModule.lastConnectivityUpdate | localeDate: "short" }}
    </ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column [heading]="'agromaat.common.measurement' | translate">
    <ng-template let-productModule="item">
      <div *ngIf="!productModule?.latestMeasurement">
        {{ "agromaat.common.noMeasurementKnown" | translate }}
      </div>
      <vdr-dropdown *ngIf="productModule?.latestMeasurement">
        <button class="button-small btn-sm" vdrDropdownTrigger>
          {{ productModule?.latestMeasurement.createdAt | localeDate: "short" }}
          <clr-icon shape="info-standard"></clr-icon>
        </button>
        <vdr-dropdown-menu>
          <div class="result-detail">
            <vdr-object-tree
              [value]="productModule?.latestMeasurement"
            ></vdr-object-tree>
          </div>
        </vdr-dropdown-menu>
      </vdr-dropdown>
    </ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column
    [heading]="'common.created-at' | translate"
    id="created-at"
    [hiddenByDefault]="true"
    [sort]="sorts.get('createdAt')"
  >
    <ng-template let-productCell="item">
      {{ productCell.createdAt | localeDate: "short" }}
    </ng-template>
  </vdr-dt2-column>
  <vdr-dt2-column
    [heading]="'common.updated-at' | translate"
    id="updated-at"
    [hiddenByDefault]="true"
    [sort]="sorts.get('updatedAt')"
  >
    <ng-template let-productCell="item">
      {{ productCell.updatedAt | localeDate: "short" }}
    </ng-template>
  </vdr-dt2-column>
</vdr-data-table-2>
