import {
    addNavMenuItem,
    addNavMenuSection,
    DataService,
    ModalService,
    NotificationService,
    registerBulkAction,
    registerFormInputComponent,
    registerPageTab,
    setDashboardWidgetLayout,
} from '@vendure/admin-ui/core';
import {
    BulkOpenProductCellDocument,
    BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument,
    BulkUpdateJofemarVisionEsPlusChannelStockDocument,
    BulkUpdateProductCellProductVariantDocument,
    BulkUpdateProductCellStateDocument,
    JofemarVisionEsPlus,
    JofemarVisionEsPlusChannel,
    JofemarNuukChannel,
    MeasureProductModuleDocument,
    ProductCell,
    ProductModule,
    RestartJofemarVisionEsPlusDocument,
    RestartProductModulesDocument,
    MeasureJofemarVisionEsPlusDocument,
    BulkUpdateJofemarNuukChannelProductVariantDocument,
    BulkUpdateJofemarNuukChannelStockDocument,
} from './generated-types';
import { Injector } from '@angular/core';
import { ProductCellsListComponent } from './components/product-cells-list/product-cells-list.component';
import { ProductCellStateSelectDialogComponent } from './components/product-cell-state-select-dialog/product-cell-state-select-dialog.component';
import { ProductVariantSelectDialogComponent } from './components/product-variant-select-dialog/product-variant-select-dialog.component';
import { JofemarVisionEsPlusChannelListComponent } from './components/jofemar-vision-es-plus-channel-list/jofemar-vision-es-plus-channel-list.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DeliverDeviceFormInputComponent } from './components/deliver-device-form-input/deliver-device-form-input.component';
import { UpdateStockDialogComponent } from './components/update-stock-dialog/update-stock-dialog.component';
import { lastValueFrom } from 'rxjs';
import { JofemarVisionEsPlusOverviewComponent } from './components/jofemar-vision-es-plus-overview/jofemar-vision-es-plus-overview.component';
import { ProductModulesOverviewComponent } from './components/product-modules-overview/product-modules-overview.component';
import { JofemarNuukOverviewComponent } from './components/jofemar-nuuk-overview/jofemar-nuuk-overview.component';
import { JofemarNuukChannelListComponent } from './components/jofemar-nuuk-channel-list/jofemar-nuuk-channel-list.component';
import { ProductCellsOverviewComponent} from './components/product-cells-overview/product-cells-overview.component';
import { DeliverType } from './ui-types';

export default [
    registerFormInputComponent('deliver-device-form-input', DeliverDeviceFormInputComponent),
    setDashboardWidgetLayout([]),
    registerBulkAction({
        location: 'product-modules-list',
        label: 'agromaat.bulk.restart-product-modules',
        icon: 'refresh',
        onClick: ({ selection, injector }: { selection: ProductModule[]; injector: Injector }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            dataService
                .mutate(RestartProductModulesDocument, {
                    input: {
                        productModuleIds: ids,
                    },
                })
                .subscribe(
                    () => {
                        notificationService.success('Modules worden herstart');
                    },
                    err => {
                        notificationService.error(err);
                    },
                );
        },
    }),
    registerBulkAction({
        location: 'jofemar-vision-es-plus-list',
        label: 'agromaat.bulk.restart-jofemars',
        icon: 'refresh',
        onClick: ({ selection, injector }: { selection: JofemarVisionEsPlus[]; injector: Injector }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            dataService
                .mutate(RestartJofemarVisionEsPlusDocument, {
                    ids,
                })
                .subscribe(
                    () => {
                        notificationService.success('Jofemars worden herstart');
                    },
                    err => {
                        notificationService.error(err);
                    },
                );
        },
    }),
    registerBulkAction({
        location: 'product-modules-list',
        label: 'agromaat.bulk.measure-product-modules',
        icon: 'cpu',
        onClick: async ({ selection, injector }: { selection: ProductModule[]; injector: Injector }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            for (const id of ids) {
                try {
                    const result = await lastValueFrom(
                        dataService.query(MeasureProductModuleDocument, {
                            productModuleId: id,
                        }).single$,
                    );

                    notificationService.success(_('agromaat.bulk.measure-product-modules-success'), {
                        id,
                        chipTemperature: result.measureProductModule?.chipTemperature || 0,
                    });
                } catch {
                    notificationService.error(_('agromaat.bulk.measure-product-modules-failed'));
                }
            }
        },
    }),
    registerBulkAction({
        location: 'jofemar-vision-es-plus-list',
        label: 'agromaat.bulk.measure-jofemar-vision-es-plus',
        icon: 'cpu',
        onClick: async ({ selection, injector }: { selection: ProductModule[]; injector: Injector }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            for (const id of ids) {
                try {
                    const result = await lastValueFrom(
                        dataService.query(MeasureJofemarVisionEsPlusDocument, {
                            id,
                        }).single$,
                    );

                    notificationService.success(_('agromaat.bulk.measure-jofemar-vision-es-plus-success'), {
                        id,
                        chipTemperature: result.measureJofemarVisionEsPlus?.lastMeasuredChipTemperature || 0,
                    });
                } catch {
                    notificationService.error(_('agromaat.bulk.measure-jofemar-vision-es-plus-failed'));
                }
            }
        },
    }),
    registerBulkAction({
        location: 'product-cells-list',
        label: 'agromaat.bulk.open-product-cell',
        icon: 'unlock',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: ProductCell[];
            injector: Injector;
            hostComponent: ProductCellsListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            dataService
                .mutate(BulkOpenProductCellDocument, {
                    input: {
                        ids,
                    },
                })
                .subscribe(
                    result => {
                        hostComponent.refresh();
                        if (result.bulkOpenProductCell.length !== ids.length) {
                            notificationService.error('common.notify-update-error', {
                                entity: 'Productvak',
                            });
                            return;
                        }
                        notificationService.success('common.notify-update-success', {
                            entity: 'Productvak',
                        });
                    },
                    () =>
                        notificationService.error('common.notify-update-error', {
                            entity: 'Productvak',
                        }),
                );
        },
    }),
    registerBulkAction({
        location: 'product-cells-list',
        label: 'agromaat.bulk.change-product-cell-state',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: ProductCell[];
            injector: Injector;
            hostComponent: ProductCellsListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(ProductCellStateSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                    },
                })
                .subscribe(res => {
                    if (res) {
                        dataService
                            .mutate(BulkUpdateProductCellStateDocument, {
                                input: {
                                    ids,
                                    state: res,
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (result.bulkUpdateProductCellState.length !== ids.length) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'Productvak',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'Productvak',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'Productvak',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'product-cells-list',
        label: 'agromaat.bulk.change-product-variant',
        icon: 'edit',
        isVisible: ({ selection }: { selection: ProductCell[] }) =>
            new Set(selection.map(({ type, productModule }) => `${type}-${productModule.vending.cooling}`))
                .size === 1,
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: ProductCell[];
            injector: Injector;
            hostComponent: ProductCellsListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        // @TODO filter based on supported product cell types
                        deliverDevice: DeliverType.VENDING,
                        productCellType: selection.map(({ type }) => type)[0],
                        cooling: selection.map(({ productModule }) => productModule.vending.cooling)[0],
                    },
                })
                .subscribe(id => {
                    if (id) {
                        dataService
                            .mutate(BulkUpdateProductCellProductVariantDocument, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (result.bulkUpdateProductCellProductVariant.length !== ids.length) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'Productvak',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'Productvak',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'Productvak',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'jofemar-vision-es-plus-channel-list',
        label: 'agromaat.bulk.change-product-variant',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: JofemarVisionEsPlusChannel[];
            injector: Injector;
            hostComponent: JofemarVisionEsPlusChannelListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        deliverDevice: DeliverType.JOFEMAR_VISION_ES_PLUS,
                    },
                })
                .subscribe(id => {
                    if (id) {
                        dataService
                            .mutate(BulkUpdateJofemarVisionEsPlusChannelProductVariantDocument, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (
                                        result.bulkUpdateJofemarVisionEsPlusChannelProductVariant.length !==
                                        ids.length
                                    ) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFlex kanaal',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'AgroFlex kanaal',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFlex kanaal',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'jofemar-vision-es-plus-channel-list',
        label: 'agromaat.bulk.change-stock',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: JofemarVisionEsPlusChannel[];
            injector: Injector;
            hostComponent: JofemarVisionEsPlusChannelListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(UpdateStockDialogComponent, {
                    size: 'md',
                    locals: {
                        stock: 0,
                    },
                })
                .subscribe(inStock => {
                    if (inStock !== undefined) {
                        dataService
                            .mutate(BulkUpdateJofemarVisionEsPlusChannelStockDocument, {
                                input: {
                                    ids,
                                    inStock,
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (
                                        result.bulkUpdateJofemarVisionEsPlusChannelStock.length !== ids.length
                                    ) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFlex kanaal',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'AgroFlex kanaal',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFlex kanaal',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'jofemar-nuuk-channel-list',
        label: 'agromaat.bulk.change-product-variant',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: JofemarNuukChannel[];
            injector: Injector;
            hostComponent: JofemarNuukChannelListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        deliverDevice: DeliverType.JOFEMAR_NUUK,
                    },
                })
                .subscribe(id => {
                    if (id) {
                        dataService
                            .mutate(BulkUpdateJofemarNuukChannelProductVariantDocument, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (
                                        result.bulkUpdateJofemarNuukChannelProductVariant.length !==
                                        ids.length
                                    ) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFreeze kanaal',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'AgroFreeze kanaal',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFreeze kanaal',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'jofemar-nuuk-channel-list',
        label: 'agromaat.bulk.change-stock',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: JofemarNuukChannel[];
            injector: Injector;
            hostComponent: JofemarNuukChannelListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(UpdateStockDialogComponent, {
                    size: 'md',
                    locals: {
                        stock: 0,
                    },
                })
                .subscribe(inStock => {
                    if (inStock !== undefined) {
                        dataService
                            .mutate(BulkUpdateJofemarNuukChannelStockDocument, {
                                input: {
                                    ids,
                                    inStock,
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (result.bulkUpdateJofemarNuukChannelStock.length !== ids.length) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFreeze kanaal',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'AgroFreeze kanaal',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFreeze kanaal',
                                    }),
                            );
                    }
                });
        },
    }),
    addNavMenuSection(
        {
            id: 'agromaat',
            label: 'agromaat.common.agromaat',
            items: [
                {
                    id: 'product-agrobox',
                    label: 'agromaat.common.agrobox',
                    routerLink: ['/extensions/agromaat-stock/agrobox'],
                    requiresPermission: 'ManageVendingStock',
                },
                {
                    id: 'product-agrobox-refill',
                    label: 'agromaat.common.agrobox-refill',
                    routerLink: ['/extensions/agromaat-stock/agrobox-refill'],
                    requiresPermission: 'ManageVendingStock',
                },
                {
                    id: 'product-agrobox-overview',
                    label: 'agromaat.common.agrobox-overview',
                    routerLink: ['/extensions/agromaat-stock/agrobox-overview'],
                    requiresPermission: 'SuperAdmin',
                },
                {
                    id: 'agroflex-channels',
                    label: 'agromaat.agroflex.agroflex',
                    routerLink: ['/extensions/agromaat-stock/agroflex-channels'],
                    requiresPermission: 'ManageJofemarVisionEsPlusStock',
                },
                {
                    id: 'agrofreeze-channels',
                    label: 'agromaat.agrofreeze.agrofreeze',
                    routerLink: ['/extensions/agromaat-stock/agrofreeze-channels'],
                    requiresPermission: 'ManageJofemarNuukStock',
                },
                {
                    id: 'agrotap',
                    label: 'agromaat.common.agrotap',
                    routerLink: ['/extensions/agromaat-stock/agrotap'],
                    requiresPermission: 'ManageMilktapStock',
                },
            ],
        },
        'catalog',
    ),
    addNavMenuItem(
        {
            id: 'product-modules',
            label: 'agromaat.common.productModules',
            routerLink: ['/extensions/agromaat-stock/product-modules'],
            requiresPermission: 'SuperAdmin',
        },
        'settings',
    ),
    addNavMenuItem(
        {
            id: 'graphql-sandbox',
            label: 'Apollo Sandbox',
            routerLink: ['/extensions/agromaat-stock/graphql-sandbox'],
            requiresPermission: 'SuperAdmin',
        },
        'settings',
    ),
    registerPageTab({
        location: 'product-modules' as any,
        tab: 'ProductModules',
        route: '/extensions/agromaat-stock/product-modules',
        component: ProductModulesOverviewComponent,
    }),
    registerPageTab({
        location: 'product-modules' as any,
        tab: 'AgroFlex',
        route: '/extensions/agromaat-stock/product-modules/agroflex',
        component: JofemarVisionEsPlusOverviewComponent,
    }),
    registerPageTab({
        location: 'product-modules' as any,
        tab: 'AgroFreeze',
        route: '/extensions/agromaat-stock/product-modules/agrofreeze',
        component: JofemarNuukOverviewComponent,
    }),
    registerPageTab({
        location: 'agrofreeze-channels' as any,
        tab: 'Bijvullen',
        route: '/extensions/agromaat-stock/agrofreeze-channels',
        component: JofemarNuukChannelListComponent,
    }),
    registerPageTab({
        location: 'product-agrobox' as any,
        tab: 'Productvakken',
        route: '/extensions/agromaat-stock/agrobox',
        component: ProductCellsOverviewComponent,
    }),
    registerPageTab({
        location: 'agroflex-channels' as any,
        tab: 'Bijvullen',
        route: '/extensions/agromaat-stock/agroflex-channels',
        component: JofemarVisionEsPlusChannelListComponent,
    }),
];
