import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DataService, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { GET_PRODUCT_LIST_POSITION, MOVE_PRODUCT } from './order-product-list.graphql';
import { GetProductListPositionQuery } from '../../generated-types';
import { CdkDrag, CdkDragDrop, CdkDragMove, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { ActivationStart, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BehaviorSubject } from 'rxjs';

type ProductItem = GetProductListPositionQuery['products']['items'][0];

@Component({
    selector: 'order-product-list',
    templateUrl: './order-product-list.component.html',
    styleUrls: ['./order-product-list.component.scss'],
    standalone: true,
    imports: [SharedModule, CdkDropList, CdkDrag],
})
export class OrderProductListComponent implements OnInit {
    private productsSubject = new BehaviorSubject<ProductItem[]>([]);
    products$ = this.productsSubject.asObservable();
    private scrollInterval: null | NodeJS.Timeout = null;

    constructor(
        private readonly router: Router,
        private readonly dataService: DataService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly notificationService: NotificationService,
    ) {}

    ngOnInit() {
        const navigateAway$ = this.router.events.pipe(filter(event => event instanceof ActivationStart));

        this.dataService
            .query<GetProductListPositionQuery>(GET_PRODUCT_LIST_POSITION)
            .refetchOnChannelChange()
            .stream$.pipe(takeUntil(navigateAway$), shareReplay(1))
            .pipe(map(result => result.products.items))
            .subscribe(products => {
                this.productsSubject.next(products);
                this.changeDetector.markForCheck();
            });
    }

    drop(event: CdkDragDrop<ProductItem>) {
        const previousProducts = [...this.productsSubject.value];
        const updatedProducts = [...this.productsSubject.value];
        moveItemInArray(updatedProducts, event.previousIndex, event.currentIndex);
        this.productsSubject.next(updatedProducts);
        this.changeDetector.markForCheck();

        this.dataService
            .mutate(MOVE_PRODUCT, {
                input: {
                    productId: event.item.data.id,
                    index: event.currentIndex,
                },
            })
            .subscribe({
                next: () => {
                    this.notificationService.success(_('common.notify-saved-changes'));
                },
                error: () => {
                    this.productsSubject.next(previousProducts);
                    this.changeDetector.detectChanges();
                    this.notificationService.error(_('common.notify-save-changes-error'));
                },
            });
    }

    onDragStarted() {
        this.scrollInterval = null;
    }

    onDragMoved(event: CdkDragMove) {
        const scrollThreshold = 150; // Pixels vanaf de rand om scrollen te activeren
        const maxScrollSpeed = 50; // Aantal pixels om te scrollen per interval
        const mouseY = event.pointerPosition.y;
        const windowHeight = window.innerHeight;

        if (mouseY < scrollThreshold) {
            this.scrollPage(-maxScrollSpeed);
        } else if (mouseY > windowHeight - scrollThreshold) {
            this.scrollPage(maxScrollSpeed);
        } else {
            if (this.scrollInterval) {
                clearInterval(this.scrollInterval);
            }
            this.scrollInterval = null;
        }
    }

    onDragEnded() {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }
    }

    private scrollPage(scrollYAmount: number) {
        if (!this.scrollInterval) {
            this.scrollInterval = setInterval(() => {
                document.querySelector('.scrollable-container')?.scrollBy(0, scrollYAmount);
            }, 10);
        }
    }
}
